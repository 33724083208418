import { getMaxHeight, getMaxWidth } from "@sushicorp/utils";
import styled from "styled-components";

import { ModalPaymentezOtpVerificationStyledProps as Props } from "./ModalPaymentezOtpVerification.types";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";
const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;
const ModalPaymentezOtpVerificationStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 54rem);
  height: min(${getMaxHeight()}, 30.8rem);
  border-radius: 1.2rem;
  background-color: var(--palette-black-s2-l17);

  & .Modal {
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--palette-black-s2-l17);
    }
  }

  .ModalPaymentezOtpVerification {
    &__header {
      padding: 1.6rem 2.4rem;
      font-weight: 700;
      font-size: 2rem;
      background-color: var(--palette-black-s2-l17);
      text-align: center;
      flex: 1;
    }

    &__title {
      color: var(--palette-white);
      font-weight: 600;
      position: absolute;
      top: 3.6rem;
      left: 2.4rem;
      right: 2.4rem;
    }

    &__otp {
      &--container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--input {
        width: 6.4rem !important;
        height: 6.4rem;
        margin: 0 0.8rem;
        font-size: 1.6rem;
        border-radius: 0.8rem;
        font-family: inherit;
        border: solid 0.1rem var(--palette-gray-s2-l21);

        &:focus-visible {
          outline: none;
        }

        @media (max-width: ${mobile}px) {
          width: 4rem !important;
          height: 5.2rem;
          margin: 0 0.4rem;
          font-size: 2rem;
        }
      }

      &--error {
        border: solid 1px red;
      }
    }

    &__error {
      display: inline-block;
      padding-top: 1.2rem;
      font-size: 1.6rem;
      color: var(--palette-red-s95-63);
    }

    &__button {
      padding: 1.2rem 4rem;
      font-family: inherit;
      font-weight: 700;
      font-size: 1.6rem;
      margin-top: 4.4rem;
      width: 84%;
      border-radius: 1.8rem;
    }
  }
`;

export default ModalPaymentezOtpVerificationStyled;
