import styled from "styled-components";

import { AddressFormStyledProps as Props } from "./AddressForm.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile, tablet } = BREAKPOINTS;

const AddressFormStyled = styled.div<Props>`
  padding-top: 1.2rem;

  .AddressForm {
    &__phone-input {
      .PhoneNumberInput {
        &__label {
          padding-bottom: 0.8rem;
          color: var(--palette-white);
          font-weight: 700;
          font-size: 1.4rem;
          margin-left: 0.8rem;
        }

        &__input {
          color: var(--palette-white);
          background-color: var(--palette-black-s2-l21);
          border: none;
          height: 4.8rem;
          border: 0.1rem solid var(--palette-black-s2-l21);
          transition: border 0.2s ease-in-out;

          &:focus {
            border-color: var(--palette-primary);
            box-shadow: none;
          }

          &:hover {
            border-color: var(--palette-primary);
            box-shadow: none;
          }
        }

        &__select {
          color: var(--palette-white);
          border: none;
          height: 4.8rem;
          background-color: var(--palette-black-s2-l21);
          border: 0.1rem solid var(--palette-black-s2-l21);
          transition: border 0.2s ease-in-out;

          &:focus {
            border-color: var(--palette-primary);
            box-shadow: none;
          }

          &:hover {
            border-color: var(--palette-primary);
            box-shadow: none;
          }

          .SelectCountry {
            &__select {
              color: var(--palette-black-s2-l21);
            }
          }
        }

        &__errorMessage {
          margin-left: 0.8rem;
        }
      }
    }

    &__comment {
      & .CommentBox {
        &__label {
          padding-bottom: 0.8rem;
          color: var(--palette-white);
          font-weight: 700;
          font-size: 1.4rem;
        }

        &__textarea {
          background-color: var(--palette-black-s2-l21);
          color: var(--palette-white);
          border: 0.1rem solid var(--palette-black-s2-l21);
          transition: border 0.2s ease-in-out;

          &:focus {
            border: 0.1rem solid var(--palette-primary);
            box-shadow: none;
          }

          &:hover {
            border: 0.1rem solid var(--palette-primary);
            box-shadow: none;
          }
        }
      }
    }

    &__text-input {
      padding-bottom: 2.4rem;

      .TextInput {
        &__label {
          padding-bottom: 0.8rem;
          color: var(--palette-white);
          font-weight: 700;
          font-size: 1.4rem;
          margin-left: 0.8rem;
        }

        &__input {
          background-color: var(--palette-black-s2-l21);
          color: var(--palette-white);
          border: none;
          height: 4.8rem;
          border: 0.1rem solid var(--palette-black-s2-l21);
          transition: border 0.2s ease-in-out;

          &:focus {
            border-color: var(--palette-primary);
            box-shadow: none;
          }

          &:hover {
            border-color: var(--palette-primary);
            box-shadow: none;
          }

          &::placeholder {
            color: var(--palette-gray-s0-l50);
          }
        }

        &__errorMessage {
          margin-left: 0.8rem;
        }
      }
    }

    &__divider {
      background-color: var(--palette-black-s2-l21);
      height: 8px;
      margin-bottom: 2rem;
    }

    &__nickname-input {
      display: none;
      width: 100%;

      .TextInput {
        &__input {
          background-color: var(--palette-black-s2-l21) !important;
          width: 100%;
          border: 0.1rem solid var(--palette-black-s2-l21) !important;
        }

        &__label {
          color: var(--palette-white) !important;
        }
      }

      &--show {
        display: block;
      }
    }

    &__checkbox {
      margin-right: 2rem;
      margin-top: 2rem;

      .Checkbox {
        &__label {
          margin-left: 2rem;
          margin-bottom: 1.2rem;
        }
      }
    }

    &__line {
      width: 100%;
      height: 0.8rem;
      background-color: var(--palette-black-s2-l21);
      margin-bottom: 2.4rem;
    }

    &__buttonGroup {
      padding-bottom: 1.2rem;
      padding-top: 1.2rem;

      &__title {
        color: var(--palette-white);
        padding-bottom: 0.4rem;
        font-size: 1.2rem;
        font-weight: 700;
      }

      &__options {
        display: flex;
        flex: 1;
        margin-bottom: 2rem;
      }
    }

    &__option {
      width: 100%;

      &:not(:first-of-type) {
        padding-left: 0.4rem;
      }

      &__radio {
        display: none;

        &:checked + .AddressForm__option__button {
          border: 0.3rem solid var(--palette-white);
          background-color: var(--palette-white);
        }
      }

      &__button {
        display: inline-flex;
        cursor: pointer;
        height: 4.4rem;
        align-items: center;
        padding: 1.2rem;
        border: 0.1rem solid var(--palette-white);
        border-radius: 0.8rem;
        text-align: center;
        font-weight: 600;
        color: var(--palette-white);
        font-size: 1.2rem;
        font-weight: 700;
        display: flex;
        justify-content: center;

        @media (max-width: ${mobile}px) {
          width: 100%;
          padding: 1.2rem 0;
        }

        svg {
          margin-right: 0.8rem;

          path {
            fill: var(--palette-white);
          }
        }

        &--selected {
          color: var(--palette-primary);

          svg {
            margin-right: 0.8rem;

            path {
              fill: var(--palette-primary);
            }
          }
        }
      }
    }

    &__phone-title,
    &__buttonGroup-title {
      color: var(--palette-gray-s0-l80);
      padding-bottom: 1.6rem;
      font-size: 1.2rem;
    }

    &__phone-title {
      padding-bottom: 0.4rem;
      line-height: 1.6rem;
    }

    &__phone-number {
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-bottom: 3.2rem;
    }

    &__error-message {
      color: var(--palette-red-s60-l60);
      font-size: 1.2rem;
      padding-bottom: 2.4rem;
    }

    &__button {
      width: 100%;
      padding: 1.2rem;
      margin-top: 0.8rem;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;

      @media (max-width: ${tablet - 1}px) {
        margin-bottom: 0.8rem;
      }
    }
  }
`;

export default AddressFormStyled;
