import Lottie from "lottie-react-web";
import React from "react";

import { backdropConfig } from "./Loading.helpers";
import Styles from "./Loading.styles";
import { LoadingProps as Props } from "./Loading.types";

import LadingAnimationJSON from "../../../../public/assets/animations/loading.json";

const Loading: React.FC<Props> = props => {
  const { className = "", show } = props;

  return (
    <Styles
      backdropConfig={backdropConfig}
      className={`Loading ${className}`}
      opened={show}
      color="--var-palette-black-s2-l17"
    >
      <div className="Loading__container">
        <Lottie
          options={{
            animationData: LadingAnimationJSON,
            autoplay: true,
            loop: true
          }}
        />
      </div>
    </Styles>
  );
};

Loading.defaultProps = {};

export default Loading;
