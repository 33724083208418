import { transparentize } from "polished";
import styled from "styled-components";

import { MapStyledProps as Props } from "./Map.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const MapStyled = styled.div<Props>`
  width: 100%;
  height: 100%;
  position: relative;

  .Map {
    &__controls {
      position: absolute;
      bottom: -0.4rem;
      display: flex;
      width: 100%;
      flex-direction: column;

      @media (max-width: ${mobile - 1}px) {
        bottom: -1.2rem;
      }
    }

    &__location-icon-container {
      width: 4.4rem;
      height: 4.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 10rem;
      box-shadow: 0 0.6rem 1.4rem rgba(0, 0, 0, 0.2);
      background-color: var(--palette-white);
      align-self: flex-end;
      margin-top: 0rem;
      margin-right: 4rem;
      margin-bottom: 4rem;
      margin-left: 4rem;
      padding: 0;

      @media (max-width: ${mobile - 1}px) {
        margin: 0 2.4rem;
        margin-bottom: 1.6rem;
      }

      path {
        fill: var(--palette-primary);
      }
    }

    &__confirm-button {
      display: flex;
      justify-content: center;
      max-width: 100%;
      font-weight: 600;
      font-size: 1.6rem;
      padding: 1.2rem 0;
      margin-bottom: 3.2rem;
      margin-left: 4rem;
      margin-right: 4rem;

      @media screen and (max-width: ${mobile - 1}px) {
        margin-right: 1.6rem;
        margin-bottom: 4rem;
        margin-left: 1.6rem;
      }
    }

    &__slider {
      max-width: 100%;
      padding-left: 4rem;

      @media (max-width: ${mobile - 1}px) {
        padding-left: 2.4rem;
      }
    }

    &__store {
      position: absolute;
      z-index: 1;
      display: flex;
      width: 100%;
      background-color: var(--palette-black-s0-l10-a8);
      padding: 0.8rem;
      justify-content: space-between;
      margin-top: -0.1rem;

      &__address {
        color: var(--palette-white);
        font-size: 1.2rem;
        text-transform: capitalize;
      }
    }

    &__out-of-coverage {
      background-color: ${() => transparentize(0.4, "#EB5757")};
    }

    &__no-coverage {
      font-weight: 600;
    }
  }
`;

export default MapStyled;
