import { useGeo } from "@sushicorp/contexts";
import { useFetchNearbyStores } from "@sushicorp/services";
import { useFetchGoogleAddressFromCoords } from "@sushicorp/services";
import { getCurrentPosition } from "@sushicorp/utils";
import { Store } from "artisn/types";
import React, { useState, useEffect } from "react";

import Styles from "./ChooseStore.styles";
import { ChooseStoreProps as Props } from "./ChooseStore.types";
import StoreList from "../StoreList/StoreList";
import InfoCard from "components/global/InfoCard/InfoCard";
import { InfoShoppingCartModal } from "components/global/InfoShoppingCartModal/InfoShoppingCartModal";
import SearchStore from "components/global/SearchStore/SearchStore";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import { notify } from "utils/common.utils";

import GeoSVG from "../../../../public/assets/images/geo.svg";
import MapSVG from "../../../../public/assets/images/map.svg";

const ChooseStore: React.FC<Props> = props => {
  const { mapStore, onClose } = props;
  const { setPreviousStep, onSelectedStore, setStep, setMapStore } = props;
  const { selectedCoordinates, setSelectedCoordinates } = useGeo();
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const nearbyStoresResponse = useFetchNearbyStores(
    selectedCoordinates,
    notify
  );
  const { data: stores, isLoading, isError } = nearbyStoresResponse;
  const { refetch: refetchNearbyStores } = nearbyStoresResponse;
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();
  const { data: predictions, error } = useFetchGoogleAddressFromCoords(
    process.env.NEXT_PUBLIC_MAPS_API_KEY ?? "",
    selectedCoordinates
  );
  const { message } = error ?? {};

  const currentPositionHandler = async () => {
    await getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        setSelectedCoordinates({ lat: latitude, lng: longitude });
        refetchNearbyStores();
        onClose?.();
      },
      error => {
        setGeoCodeAddress(error.message);
      }
    );
  };

  const changeHandler = (store: Store) => {
    setMapStore(store);
    setPreviousStep(1);
    setStep(2);
  };

  const onSelectedStoreInformation = (store: Store) => {
    setMapStore(store);
    setPreviousStep(1);
    setStep(3);
  };

  const clickHandler = async () => {
    if (!isEmptyShoppingCart) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (isConfirmed) {
          emptyCartHandler();
          currentPositionHandler();
          return;
        }
        throw new Error("The promise in the modal is rejected");
      } catch (e) {
        console.error(e.message);
      }
      return;
    }
    currentPositionHandler();
  };

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
  }, [predictions]);

  useEffect(() => {
    if (message) console.error(message);
  }, [message]);

  return (
    <Styles className="ChooseStore">
      <SearchStore onChange={changeHandler} />
      <div className="ChooseStore__options">
        <InfoCard
          Icon={GeoSVG}
          title={geoCodeAddress}
          className="ChooseStore__current-position"
          value="Seleccionar según mi ubicación actual"
          onContentClick={clickHandler}
        />
        <InfoCard
          Icon={MapSVG}
          className="ChooseStore__map-search"
          value="Buscar en el mapa"
          onContentClick={() => setStep(2)}
        />
        {message ? (
          <p className="ChooseStore__reverse-geocode-error">{message}</p>
        ) : null}
      </div>
      <StoreList
        stores={stores}
        isLoading={isLoading}
        isError={isError}
        selectedStore={mapStore}
        onStoreClick={onSelectedStore}
        onStoreInfoClick={onSelectedStoreInformation}
      />
    </Styles>
  );
};

ChooseStore.defaultProps = {};

export default ChooseStore;
