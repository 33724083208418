import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useCountries, usePayments, useProducts } from "@sushicorp/contexts";
import { useShoppingCart as useShoppingCartContext } from "@sushicorp/contexts";
import { useGlobal } from "@sushicorp/contexts";
import { loadScript } from "@sushicorp/utils";
import { onSnapshot, doc } from "firebase/firestore";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { memo, useEffect, useState } from "react";

import Styles from "./Layout.styles";
import { LayoutProps as Props } from "./Layout.types";
import BillingDataFoundedModal from "../BillingDataFoundedModal/BillingDataFoundedModal";
import Error from "../Error/Error";
import Loading from "../Loading/Loading";
import ModalPaymentezOtpVerification from "../ModalPaymentezOtpVerification/ModalPaymentezOtpVerification";
import ProductModal from "../ProductModal/ProductModal";
import SearchBarProductsDrawer from "../drawer/SearchBarProductsDrawer/SearchBarProductsDrawer";
import ShoppingCartDrawer from "../drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import ShoppingCartNotifications from "../notifications/ShoppingCartNotifications/ShoppingCartNotifications";
import DataProtectionModal from "components/global/DataProtectionModal/DataProtectionModal";
import MergeCartModal from "components/global/MergeCartModal/MergeCartModal";
import OutOfServiceModal from "components/global/OutOfServiceModal/OutOfServiceModal";
import ShareCartModal from "components/global/ShareCartModal/ShareCartModal";
import UpdateAppModal from "components/global/UpdateAppModal/UpdateAppModal";
import StoreNotification from "components/global/notifications/StoreNotification/StoreNotification";
import { getENVs, db } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useSetupArtisn from "hooks/artisn/useSetupArtisn";
import useCoupons from "hooks/useCoupons/useCoupons";
import useListeners from "hooks/useListeners";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import variables from "styles/util/variables";
import "config/services.config";

const { TITLE, FEATURE_FLAGS, GENERAL, APP } = CONSTANTS;
const { WITH_SHARE_SHOPPING_CART, WITH_PRODUCT_MODAL } = FEATURE_FLAGS;
const { WITH_ANONYMOUS_SHOPPING_CART_TOKEN, WITH_CART_DRAWER } = FEATURE_FLAGS;
const { WITH_PURCHASE, WITH_DATA_PROTECTION } = FEATURE_FLAGS;
const { VERSION_CODE } = GENERAL;
const { APPLE_STORE_URL, PLAY_STORE_URL, WEB_URL } = APP;

const Layout: React.FC<Props> = props => {
  const { children } = props;
  useSetupArtisn();
  useListeners();
  const { listenShoppingCart } = useShoppingCart();
  const { listenBenefitsWallet } = useCoupons();
  const shoppingCartContext = useShoppingCartContext();
  const { openShoppingCartDrawer } = shoppingCartContext;
  const { setOpenShoppingCartDrawer } = shoppingCartContext;
  const { openProductModal, setOpenProductModal } = useProducts();
  const { openSearchBarDrawer, setOpenSearchBarDrawer } = useProducts();
  const { asPath } = useRouter();
  const { setIsJQueryLoaded } = usePayments();
  const { setErrorConfig, errorConfig } = useGlobal();
  const { loadingConfig, openAcceptedPrivacyDataModal } = useGlobal();
  const { locale } = useCountries().selectedCountry;
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const [remoteVersionCode, setRemoteVersionCode] = useState("");
  const [isOutOfService, setIsOutOfService] = useState(false);
  const [updateApp, setUpdateApp] = useState(false);
  const { show: showError, title, description } = errorConfig ?? {};
  const { show: showLoading } = loadingConfig ?? {};
  const isMobile = isIOS || isAndroid;

  /**
   * Function to close error modal.
   *
   * @since 1.0.0
   */
  const closeModalHandler = () => {
    setErrorConfig({
      show: false
    });
  };

  useEffect(() => {
    return listenShoppingCart?.();
  }, [listenShoppingCart]);

  useEffect(() => {
    return listenBenefitsWallet?.();
  }, [listenBenefitsWallet]);

  useEffect(() => {
    if (isIOS) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }

    if (isMobile) {
      StatusBar.setStyle({ style: Style.Light });
    }

    if (isAndroid) {
      StatusBar.setBackgroundColor({ color: variables.palette["white-hex"] });
    }
  }, [isAndroid, isIOS, isMobile]);

  useEffect(() => {
    (async () => {
      try {
        if (
          isMobile &&
          remoteVersionCode &&
          remoteVersionCode !== "0" &&
          remoteVersionCode !== VERSION_CODE
        ) {
          const semver = await import("semver");
          setUpdateApp(semver.default.gte(remoteVersionCode, VERSION_CODE));
          return;
        }
        setUpdateApp(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isMobile, remoteVersionCode]);

  useEffect(() => {
    (async () => {
      try {
        onSnapshot(doc(db, "appParameters/updateSupport"), doc => {
          if (doc.exists() && doc.data()) {
            setRemoteVersionCode(doc.data().versionCode);
            setIsOutOfService(doc.data().outOfServiceWeb);
          }
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    loadScript({
      src: "https://code.jquery.com/jquery-1.11.3.min.js",
      id: "jquery",
      charSet: "UTF-8"
    });
    setIsJQueryLoaded(true);
  }, [setIsJQueryLoaded]);

  return (
    <Styles className="Layout">
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <title>{TITLE}</title>
        <meta
          name="keywords"
          content="Noe Sushi Bar Quito, Guayaquil y Cuenca"
        />
      </Head>
      <Script
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${getENVs?.mapsApiKey}&libraries=places&language=${locale}`}
      />
      {children}
      <StoreNotification />
      {WITH_SHARE_SHOPPING_CART ? <ShareCartModal /> : null}
      {WITH_ANONYMOUS_SHOPPING_CART_TOKEN ? <MergeCartModal /> : null}
      {isOutOfService || (isMobile && remoteVersionCode === "0") ? (
        <OutOfServiceModal url={WEB_URL} />
      ) : null}
      {updateApp ? (
        <UpdateAppModal storeUrl={isIOS ? APPLE_STORE_URL : PLAY_STORE_URL} />
      ) : null}
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer
          opened={openShoppingCartDrawer}
          onClose={() => setOpenShoppingCartDrawer(prev => !prev)}
        />
      ) : null}
      <SearchBarProductsDrawer
        opened={openSearchBarDrawer}
        onClose={() => setOpenSearchBarDrawer(prev => !prev)}
      />
      {WITH_PRODUCT_MODAL ? (
        <ProductModal
          isOpen={!!openProductModal}
          onClose={() => setOpenProductModal("")}
          productId={openProductModal}
        />
      ) : null}
      <ShoppingCartNotifications />
      <ModalPaymentezOtpVerification />
      <BillingDataFoundedModal />
      {WITH_DATA_PROTECTION ? (
        <DataProtectionModal
          opened={openAcceptedPrivacyDataModal && !asPath.includes("terms")}
        />
      ) : null}
      <Loading show={showLoading} />
      <Error
        show={showError}
        title={title}
        description={description}
        closeModalHandler={closeModalHandler}
      />
    </Styles>
  );
};

Layout.defaultProps = {};

export default memo(Layout);
