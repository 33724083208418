import styled from "styled-components";

import { ShippingAddressModalStyledProps as Props } from "./ShippingAddressModal.types";

const ShippingAddressModalStyled = styled.div<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--palette-black-s2-l17);

  .ShippingAddressModal {
    &__header {
      background-color: var(--palette-black-s2-l17);
      padding: 1.6rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
      color: var(--palette-white);
    }

    &__wrapper {
      display: flex;
      padding: 0.8rem 1.6rem;
      flex: 1;
      flex-direction: column;
    }

    &__list {
      padding-top: 3.2rem;
    }
  }
`;

export default ShippingAddressModalStyled;
