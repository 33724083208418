import { useGeo } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { goToBenefitDetail } from "@sushicorp/utils";
import { applyBenefit, getShoppingCartProducts } from "artisn/shopping-cart";
import { Benefit } from "artisn/types";
import React, { useState } from "react";

import Styles, { ModalHeaderStyled, ModalStyled } from "./CouponsModal.styles";
import { CouponsModalProps as Props } from "./CouponsModal.types";
import BenefitsList from "../BenefitsList/BenefitsList";
import RedeemCouponInput from "components/Cart/RedeemCoupon/RedeemCouponInput/RedeemCouponInput";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useShippingCost from "hooks/useShippingCost";

import CloseSVG from "../../../../public/assets/images/close.svg";

const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;
const { API_URL } = CONSTANTS.API;

const CouponsModal: React.FC<Props> = props => {
  const { opened, benefits, className, redeemCouponError } = props;
  const { setRedeemCouponError, onClose, redeemHandler } = props;
  const { openProductModal } = props;
  const t = useI18n();
  const { isAnonymous = false, uid } = useAuth();
  const { shoppingCart, setTemporalBenefit } = useShoppingCart();
  const shippingCost = useShippingCost();
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = selectedCoordinates ?? {};

  const [couponCode, setCouponCode] = useState("");
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>();
  const [applyBenefitError, setApplyBenefitError] = useState("");
  const empty = benefits && benefits.length === 0;
  const { benefits: cartBenefits } = shoppingCart ?? {};
  const selectedCartBenefit = cartBenefits ? cartBenefits[0] : undefined;
  const disable = empty || !selectedBenefit || !!selectedCartBenefit;

  const resetLocalStates = () => {
    setSelectedBenefit(undefined);
    setApplyBenefitError("");
    setRedeemCouponError("");
    setCouponCode("");
  };

  const closeHandler = () => {
    resetLocalStates();
    onClose?.();
  };

  const applyHandler = async () => {
    if (!selectedBenefit || !shoppingCart) return;
    if (!uid) return;

    try {
      setApplyBenefitError("");
      const { benefitId, type } = selectedBenefit;
      if (type === "PRODUCT") {
        setTemporalBenefit(selectedBenefit);

        if (WITH_PRODUCT_MODAL) {
          closeHandler();
          openProductModal();
          return;
        }
        goToBenefitDetail(selectedBenefit);
        return;
      }

      if (
        type === "ALTER_DELIVERY" ||
        type === "DISCOUNT_FIXED" ||
        type === "DISCOUNT_PERCENTAGE"
      ) {
        setTemporalBenefit(undefined);
        if (!getShoppingCartProducts(shoppingCart).length) {
          throw new Error(
            "Can't apply benefit because there are no products in the cart"
          );
        }
        const headers = await buildArtisnHeaders();
        await applyBenefit(
          {
            benefitId,
            latitude: lat,
            longitude: lng,
            shippingCost,
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            anonymous: isAnonymous,
            shoppingCartId: shoppingCart.id,
            apiURL: API_URL,
            customerId: uid,
            accountId: ACCOUNT_ID
          },
          headers
        );
        closeHandler();
      }
    } catch (e) {
      setApplyBenefitError(e.message);
    }
  };

  const modalHeaderNode = (
    <ModalHeaderStyled>
      <p className="CouponsModal__header">{t.coupons.couponWallet}</p>
    </ModalHeaderStyled>
  );

  return (
    <ModalStyled
      {...props}
      closeOnClickOutside
      opened={opened}
      closeIcon={<CloseSVG />}
      header={modalHeaderNode}
      backdropConfig={{ onClick: closeHandler }}
      onClose={closeHandler}
      className="CouponsModal__container"
    >
      <Styles className={`CouponsModal ${className}`}>
        <div className="CouponsModal__header">
          {empty ? (
            <div className="CouponsModal__input">
              <RedeemCouponInput
                disabled={!benefits}
                couponCode={couponCode}
                onRedeem={redeemHandler}
                setCouponCode={setCouponCode}
                showLabel={false}
                className="CouponsModal__coupon-input"
              />
            </div>
          ) : null}

          {redeemCouponError ? (
            <p className="CouponsModal__error CouponsModal__redeem-error">
              {redeemCouponError}
            </p>
          ) : null}
        </div>
        <div className="CouponsModal__BenefitsList">
          <BenefitsList
            benefits={benefits}
            selectedBenefit={selectedBenefit}
            setSelectedBenefit={setSelectedBenefit}
            showRemove={false}
            inCart
            inModal
          />
        </div>
        <div className="CouponsModal__apply">
          {applyBenefitError ? (
            <p className="CouponsModal__error CouponsModal__apply-error">
              {applyBenefitError}
            </p>
          ) : null}
          <Button
            type="FILLED"
            color="primary"
            className="CouponsModal__apply__button"
            disabled={disable}
            onClick={applyHandler}
          >
            {t.common.apply}
          </Button>
        </div>
      </Styles>
    </ModalStyled>
  );
};

CouponsModal.defaultProps = {
  className: ""
};

export default CouponsModal;
