import styled from "styled-components";

import { ThirdLevelModifierStyledProps as Props } from "./ThirdLevelModifier.types";

const ThirdLevelModifierStyled = styled.div<Props>`
  .ThirdLevelModifier {
    &__modifier {
      display: flex;
      justify-content: space-between;
      padding-top: 0.4rem;
      cursor: ${props => (props.disabled ? "default" : "pointer")};

      svg {
        path {
          fill: ${props =>
            props.disabled
              ? "var(--palette-gray-s0-l35)"
              : "var(--palette-white)"};
        }
      }
    }

    &__title {
      font-size: 1.2rem;
      color: ${props =>
        props.disabled ? "var(--palette-gray-s0-l35)" : "var(--palette-white)"};

      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.4rem;
      padding-left: 0;
    }

    &__error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }
  }
`;

export default ThirdLevelModifierStyled;
