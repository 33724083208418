import { defaultFunction } from "@sushicorp/utils";
import React from "react";

import Styles from "./NoCoverageNotification.styles";
import { NoCoverageNotificationProps as Props } from "./NoCoverageNotification.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";

import LocationSVG from "../../../../../public/assets/images/location.svg";

const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const NoCoverageNotification: React.FC<Props> = props => {
  const { className, onClose = defaultFunction, opened = true } = props;
  const { onButton } = props;
  const t = useI18n();

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`NoCoverageNotification ${className}`}
      opened={opened}
      onClose={onClose}
    >
      <div className="NoCoverageNotification__icon">
        <LocationSVG />
      </div>
      <p className="NoCoverageNotification__title">
        {t.notifications.outCoverage}
      </p>
      <p className="NoCoverageNotification__description">
        {t.notifications.changeLocationContinue}
      </p>
      <Button
        type="BORDER"
        className="NoCoverageNotification__button NoCoverageNotification__button--desktop"
        onClick={onButton}
      >
        {t.notifications.changeLocation}
      </Button>
      <Button
        type="BORDER"
        className="NoCoverageNotification__button NoCoverageNotification__button--mobile"
        onClick={onButton}
      >
        {t.common.change}
      </Button>
    </Styles>
  );
};

NoCoverageNotification.defaultProps = {
  className: ""
};

export default NoCoverageNotification;
