import { lighten, darken } from "polished";

const primary = "hsla(205, 94%, 34%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.45, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "#4811d4",
  "purple-s85-l45": primary,
  "purple-s72-l92": "hsla(257, 72%, 92%, 1)",
  primary: "hsla(205, 94%, 34%, 1)",
  "green-s100-l40": "hsla(88, 100%, 40%, 1)",
  "green-s63-l42": "hsla(145, 63%, 42%, 1)",
  "red-s69-l63": "hsla(2, 69%, 63%, 1)",
  "red-s61-l87": "hsla(0, 61%, 87%, 1)",
  "red-s60-l60": "hsla(2, 60%, 60%, 1)",
  "red-s95-63": "hsla(0, 79%, 63%, 1)",
  "red-s95-l66": "hsla(0, 95%, 66%, 1)",
  "blue-s94-l34": primary,
  "blue-s68-l26": "hsla(205, 94%, 34%, 1)",
  "blue-s70-l70": "hsla(205, 70%, 70%, 1)",
  "blue-s89-l52": "hsla(214, 89%, 52%, 1)",
  "blue-h214-s89-l52": "hsla(214, 89%, 52%,1)",
  "yellow-s100-l55": "hsla(50, 100%, 54%, 1)",
  // This variable is for capacitor use only, it represents the black color.
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  "black-s0-a1": "hsla(0, 0%, 0%, 0.1)",
  "black-s0-a25": "hsla(0, 0%, 0%, 0.25)",
  "black-s0-a85": "hsla(0, 0%, 0%, 0.85)",
  "black-h60-s25-l02-10": "hsla(60, 25%, 2%,1)",
  "black-h60-s25-l10-a10": "hsla(60, 25%, 1%, 1)",
  "black-h60-s25-l2-a10": "hsla(60, 25%, 2%, 1)",
  "black-s0-l10": "hsla(0, 0%, 10%, 1)",
  "black-s0-l10-a8": "hsla(0, 0%, 10%, 0.8)",
  "black-s0-l9": "hsla(0, 0%, 9%, 1)",
  "black-s25-l2": "hsla(60, 25%, 2%, 1)",
  "black-s0-l4": "hsla(0, 0%, 4%, 1)",
  "black-s3-l11": "hsla(210, 3%, 11%, 1)",
  "black-s2-l17": "hsla(240, 2%, 17%, 1)",
  "black-s2-l21": "hsla(240, 2%, 21%, 1)",
  "black-s2-l39": "hsla(165, 2%, 39%, 1)",
  "gray-h240-s2-l21": "hsla(240, 2%, 21%, 1)",
  "gray-s0-l20-a10": "hsla(0, 0%, 20%, 10)",
  "gray-s0-l50-a10": "hsla(0, 0%, 50%, 1)",
  "gray-s0-l31": "hsla(0, 0%, 31%, 1)",
  "gray-s0-l97-a10": "hsla(0, 0%, 97%, 1)",
  "gray-l90-a10": "hsla(0, 0%, 90%,1)",
  "gray-s0-l97": "hsla(0, 0%, 97%, 1)",
  "gray-h20-s16-l96": "hsla(20, 16%, 96%, 1)",
  "gray-h220-s06-l90": "hsla(220, 6%, 90%, 1)",
  "gray-s0-l73": "hsla(0, 0%, 73%, 1)",
  "gray-s0-l20-a04": "hsla(0, 0%, 20%, 0.04)",
  "gray-s0-l20-a08": "hsla(0, 0%, 20%, 0.08)",
  "gray-s0-l20-a16": "hsla(0, 0%, 20%, 0.16)",
  "gray-s0-l20-a20": "hsla(0, 0%, 20%, 0.20)",
  "gray-s0-l20-a70": "hsla(0, 0%, 20%, 0.70)",
  "gray-s0-l10": "hsla(0, 0%, 10%, 1)",
  "gray-s0-l16": "hsla(0, 0%, 16%, 1)",
  "gray-s0-l17": "hsl(240, 2%, 17%, 1)",
  "gray-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l25": "hsla(0, 0%, 25%, 1)",
  "gray-s0-l30": "hsla(0, 0%, 30%, 1)",
  "gray-s0-l50": "hsla(0, 0%, 50%, 1)",
  "gray-s0-l51": "hsla(0, 0%, 51%, 1)",
  "gray-s0-l61": "hsla(0, 0%, 61%, 1)",
  "gray-s0-l74": "hsla(0, 0%, 74%, 1)",
  "gray-s0-l35": "hsla(0, 0%, 35%, 1)",
  "gray-s0-l40-a40": "hsla(0, 0%, 40%, 0.4)",
  "gray-s0-l40": "hsla(0, 0%, 40%, 1)",
  "gray-s0-l48": "hsla(0, 0%, 48%, 1)",
  "gray-s0-l70-a48": "hsla(0, 0%, 70%, 0.48)",
  "gray-s0-l77": "hsla(0, 0%, 77%, 1)",
  "gray-s0-l70": "hsla(0, 0%, 70%, 1)",
  "gray-s0-l80": "hsla(0, 0%, 80%, 1)",
  "gray-s0-l85": "hsla(0, 0%, 85%, 1)",
  "gray-s0-l90": "hsla(0, 0%, 90%, 1)",
  "gray-s0-l92": "hsla(0, 0%, 92%, 1)",
  "gray-s0-l95": "hsla(0, 0%, 95%, 1)",
  "gray-s0-l98": "hsla(0, 0%, 98%, 1)",
  "gray-s0-l100": "hsla(0, 0%, 100%, 1)",
  "white-s0-l10-a05": "hsla(0, 0%, 100%, 0.5)",
  "orange-h19-s72-l51-a10": "hsla(19, 72%, 51%,1)",
  "yellow-h38-s92-l59-a10": "hsla(38, 92%, 59%,1)",
  "pink-h28-s68-l72-a10": "hsla(28, 68%, 72%,1)",

  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  }
};

const fonts = {
  primary: "'Montserrat', sans-serif"
};

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns:
      "[fullBleed-start] minmax(1.6rem, 1fr) [content-start] minmax(0, 160rem) [content-end] minmax(1.6rem, 1fr) [fullBleed-end]"
  },
  navbar: {
    height: "calc(9.6rem + env(safe-area-inset-top))",
    "height-tablet": "calc(15.6rem + env(safe-area-inset-top))",
    "height-mobile": "calc(18.4rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(12.8rem + env(safe-area-inset-top))",
    "height-mobile-without-bottom-buttons":
      "calc(10.8rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
