import styled from "styled-components";

import { RedeemCouponInputStyledProps as Props } from "./RedeemCouponInput.types";

const RedeemCouponInputStyled = styled.div<Props>`
  position: relative;
  width: 100%;

  .RedeemCouponInput {
    &__text-input {
      padding-top: 2.4rem;

      label.TextInput__label {
        font-family: inherit;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: var(--palette-white);
        font-weight: 400;
        position: absolute;
        top: -1.2rem;
        padding-left: 0;
      }

      input.TextInput__input {
        height: 7.2rem;
        padding: 1.6rem;
        background-color: var(--palette-black-s2-l21);
        padding: 0.8rem 10.8rem 1.2rem 1.6rem;
        color: var(--palette-white);
        font-size: 1.6rem;
        font-weight: 700;
        border: none !important;
        border-radius: 0.4rem;

        &:focus,
        &:active,
        &:hover {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }

    &__input-button {
      position: absolute;
      top: 4rem;
      right: 1.2rem;
      height: 4rem;
      padding: 1.6rem 1.2rem;
      font-weight: 600;
      z-index: 1;
      font-size: 1.6rem;
      border: var(--palette-black-s2-l17) !important;
      background-color: var(--palette-black-s2-l17) !important;
      color: var(--palette-white);

      .Button__loading {
        svg {
          path {
            fill: var(--palette-white);
          }
        }
      }
    }
  }
`;

export default RedeemCouponInputStyled;
