import { useCatalogues, useCountries, useProducts } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useVendors } from "@sushicorp/contexts";
import { useFetchProducts } from "@sushicorp/services";
import { dropdownConfigs, formatByCurrency } from "@sushicorp/utils";
import { getSanitizedProductTotals } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { Product } from "artisn/types";
import { SearchBar, useSearchBar, Image } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";

import { imageConfig } from "./SearchBarProducts.helpers";
import Styles from "./SearchBarProducts.styles";
import { SearchBarContentStyled as ContentStyles } from "./SearchBarProducts.styles";
import { SearchBarProductsProps as Props } from "./SearchBarProducts.types";
import Button from "../Button/Button";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import { notify } from "utils/common.utils";

import ClockSVG from "../../../../public/assets/images/clock.svg";

const { Item } = SearchBar;
const { logSearchProductAttempt } = events.product;
const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_BOTTOM_SEARCH } = FEATURE_FLAGS;

const SearchBarProducts: React.FC<Props> = props => {
  const { className, isDrawer = false } = props;
  const [queryString, setQueryString] = useState("");
  const t = useI18n();
  const router = useRouter();
  const { setOpenProductModal, setOpenSearchBarDrawer } = useProducts();
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const { selectedCountry } = useCountries();
  const { storeId } = selectedStore ?? {};
  const instanceSearchBar = useSearchBar({
    name: "principal-searchBar"
  });
  const productsResponse = useFetchProducts(
    {
      vendorId: selectedVendorId,
      catalogueId: selectedCatalogueId,
      storeId: storeId!,
      size: 10,
      query: queryString
    },
    !!queryString,
    true,
    notify
  );

  const [showSearchBarContent, setShowSearchBarContent] = useState(false);
  const { decimals } = selectedCountry ?? {};
  const { data } = productsResponse;
  const { cleanSearchHistory, recentSearches } = instanceSearchBar;

  const productsArray = useMemo(() => {
    return data?.pages.flatMap((page, pageIndex) => page.data);
  }, [data]);

  /**
   * Execute in product item click event.
   *
   * @since 1.0.0
   * @param {Product} product Product data.
   */
  const onClickItem = (product: Product) => {
    const { productId } = product;
    setQueryString("");
    setOpenProductModal(productId);
  };

  /**
   * Execute in change event.
   *
   * @since 1.0.0
   * @param {string} value Query string value.
   */
  const queryChangeHandler = (value: string) => {
    setQueryString(value);

    logSearchProductAttempt({
      query: value,
      content_type: "food"
    });
  };

  /**
   * Execute in input click event.
   *
   * @since 1.0.0
   */
  const onClick = () => {
    setShowSearchBarContent(true);
  };

  /**
   * Execute in button cancel click event-
   *
   * @since 1.0.0
   */
  const onClickCancel = () => {
    setShowSearchBarContent(false);
    setOpenSearchBarDrawer(false);
  };

  /**
   * Execute in form submit event.
   *
   * @param {string} query Query string value.
   */
  const onSubmit = (query: string) => {
    logSearchProductAttempt({ query });
    setOpenSearchBarDrawer(false);

    router.push({
      pathname: "/products",
      query: { q: query }
    });
  };

  /**
   * Get product's formatted price
   *
   * @since 1.0.0
   * @param {Product} product Product data.
   * @returns Formatted price.
   */
  const getFormattedPrice = (product: Product) => {
    const { totals } = getSanitizedProductTotals(product, { decimals });
    const { unitNetPrice } = totals ?? {};
    const normal = formatByCurrency(Number(unitNetPrice ?? 0), {
      ...selectedCountry,
      locale: "en-US"
    });
    return normal;
  };

  /**
   * Render search's content results
   *
   * @since 1.0.0
   * @returns Content results
   */
  const renderContentResults = () => {
    return (
      <ContentStyles className="SearchBarContent">
        <div className="SearchBarContent__recent-searches-container">
          <div className="SearchBarContent__section-info">
            <h3 className="SearchBarContent__section-title">
              {t.productsSearch.recentSearches}
            </h3>
            <span
              onClick={cleanSearchHistory}
              className="SearchBarContent__clean-button"
            >
              {t.productsSearch.clearHistory}
            </span>
          </div>
          {recentSearches.length ? (
            <div className="SearchBarContent__recent-searches-item-container">
              {recentSearches.map((item, index) => (
                <Item
                  key={index}
                  className="SearchBarContent__recent-searches-item"
                  query={item}
                >
                  <p className="SearchBarContent__recent-searches-query">
                    {item}
                  </p>
                  <ClockSVG />
                </Item>
              ))}
            </div>
          ) : null}
        </div>
        <div className="SearchBarContent__content-container">
          <div className="SearchBarContent__section-info">
            <h3 className="SearchBarContent__section-title SearchBarContent__section-padding">
              {t.productsSearch.resultsOf} &quot;{`${queryString}`}&quot;
            </h3>
          </div>
          {productsArray?.map((item, index) => {
            const { name, images, description } = item;

            return (
              <Item
                key={index}
                className="SearchBarContent__dropdown-item"
                onClick={() => onClickItem(item)}
                query={name}
              >
                <Image
                  alt={name}
                  image={images[0]}
                  config={imageConfig}
                  placeholder="blur"
                  errorImage="/assets/images/fallback-noe.png"
                />
                <div className="SearchBarContent__item-info">
                  <p className="SearchBarContent__item-name">{name}</p>
                  <p className="SearchBarContent__item-description">
                    {description}
                  </p>
                  <p className="SearchBarContent__item-price">
                    {getFormattedPrice(item)}
                  </p>
                </div>
              </Item>
            );
          })}
        </div>
      </ContentStyles>
    );
  };

  const backdropConfig = {
    onClick: onClickCancel
  };

  return (
    <Styles
      className={`SearchBarProducts ${className}`}
      withBottomSearch={WITH_BOTTOM_SEARCH}
    >
      <SearchBar
        className="SearchBarProducts__search-bar field"
        placeholder={t.common.search}
        onSubmit={onSubmit}
        instance={instanceSearchBar}
        onInputClick={onClick}
        onChange={queryChangeHandler}
        onInputBlur={onClickCancel}
        onClear={() => {
          setQueryString("");
          setShowSearchBarContent(false);
        }}
        dropdownConfigs={{
          ...dropdownConfigs,
          showDropdown: showSearchBarContent,
          position: WITH_BOTTOM_SEARCH ? "center" : "left"
        }}
        backdropConfig={backdropConfig}
      >
        {queryString ? renderContentResults() : null}
      </SearchBar>
      {isDrawer ? (
        <Button
          className="SearchBarProducts__cancel"
          type="LINK"
          onClick={() => setOpenSearchBarDrawer(prev => !prev)}
        >
          {t.common.cancel}
        </Button>
      ) : null}
    </Styles>
  );
};

SearchBarProducts.defaultProps = {
  className: ""
};

export default SearchBarProducts;
