import styled from "styled-components";

import { SearchBarProductsStyledProps as Props } from "./SearchBarProducts.types";
import CONSTANTS from "config/constants";

const { mobile, tablet, desktop } = CONSTANTS.BREAKPOINTS;

const SearchBarProductsStyled = styled.div<Props>`
  display: flex;
  background-color: var(--palette-black-s2-l17);
  position: relative;

  .SearchBarProducts {
    &__search-bar {
      width: 100%;
      max-width: 40rem;

      @media (max-width: ${desktop - 1}px) {
        max-width: 100%;
      }

      & input {
        background-color: transparent;
      }

      .SearchBar {
        &__wrapper {
          height: 4.8rem;
          border-radius: 10rem;
          padding: 0.4rem 0.8rem;
          background-color: var(--palette-black-s2-l17);

          @media (max-width: ${tablet}px) {
            height: 4rem;
          }
        }

        &__btn-search {
          background-color: transparent;
        }

        &__btn-container {
          & > * {
            background-color: transparent;
          }
        }
      }
    }

    &__btn-cancel {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      font-size: 1.2rem;
      font-weight: 600;
      background-color: var(--palette-gray-s0-l98);
      color: var(--palette-primary-dark);
    }

    &__cancel {
      padding: 0.8rem 1.6rem;
      grid-area: cancel;

      .Button__wrapper {
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }

  &&& .SearchBar__btn-search {
    padding-left: 0;
  }

  &&& .SearchBar__input {
    padding: 1rem 1.2rem;
    border: none;
    line-height: 2.4rem;
    width: 100%;
    background-color: var(--palette-black-s2-l17);
    font-weight: 400;
    font-size: 1.6rem;

    &:focus {
      box-shadow: none;
    }

    &:hover:not(:disabled),
    &:focus {
      border: none;
    }

    @media (max-width: ${tablet - 1}px) {
      max-width: "100%";
    }
  }
`;

const SearchBarContentStyled = styled.div<Props>`
  position: fixed;
  width: inherit;
  max-height: calc(100vh - 15.6rem);
  overflow: auto;
  padding-top: 0.8rem;
  filter: drop-shadow(0rem 0.4rem 0.4rem var(--palette-gray-s0-l20-a16))
    drop-shadow(0rem 0.4rem 5.6rem var(--palette-gray-s0-l20-a16));
  z-index: 2;
  background-color: var(--palette-black-s3-l11);

  @media (max-width: ${tablet}px) {
    left: 0;
    width: calc(100%);
  }

  .SearchBarContent {
    &__container {
      background-color: var(--palette-black-s2-l17);
    }

    &__clean-button {
      padding: 0.2rem 1.2rem;
      border-radius: 4.8rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem 1.2rem;
      font-size: 1.2rem;
      color: var(--palette-white);
      text-align: center;
      border-radius: 4.8rem;
      background-color: var(--palette-gray-s0-l10);
    }

    &__recent-searches-container {
      padding: 1.2rem 2.4rem;
      background-color: var(--palette-black-s2-l17);
      margin-bottom: 0.8rem;
      max-width: 100%;

      @media (max-width: ${mobile}px) {
        padding: 1.2rem;
      }
    }

    &__section-info {
      display: flex;
      justify-content: space-between;
      padding: 0 0.8rem;
      padding-bottom: 1.6rem;
    }

    &__recent-searches-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem;
      cursor: pointer;
      background-color: var(--palette-black-s2-l17);

      > svg {
        path {
          fill: var(--palette-white);
        }
      }
    }

    &__recent-searches-query {
      max-width: 90%;
      font-size: 1.6rem;
      color: var(--palette-white);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      background-color: var(--palette-black-s2-l17);
    }

    &__content-container {
      padding: 1.2rem 0;
      background-color: var(--palette-black-s2-l17);
    }

    &__section-title {
      font-family: inherit;
      font-size: 1.4rem;
      color: var(--palette-white);
      font-weight: 700;
    }

    &__section-padding {
      padding: 0 2.4rem;
    }

    &__dropdown-item {
      padding: 0.4rem 1.6rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: var(--palette-black-s2-l17);

      &:not(:last-child) {
        border-bottom: 0.4rem solid var(--palette-black-s3-l11);
      }

      .Image {
        min-width: 10rem;
      }
    }

    &__item-info {
      padding: 0.8rem;
      overflow: hidden;
    }

    &__item-name {
      font-size: 1.2rem;
      color: var(--palette-white);
      max-width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 700;
    }

    &__item-price {
      margin-top: 0.4rem;
      font-size: 1.2rem;
      color: var(--palette-white);
      font-weight: 700;
    }

    &__item-description {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l80);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export { SearchBarContentStyled };
export default SearchBarProductsStyled;
