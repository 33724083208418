import styled from "styled-components";

import { CounterStyledProps as Props } from "./Counter.types";

const CounterStyled = styled.div<Props>`
  display: inline-block;
  background-color: ${props =>
    props.disabled
      ? "var(--palette-black-s0-l10)"
      : "var(--palette-black-s0-l10)"};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  border-radius: 0.4rem;
  overflow: hidden;
  max-height: 3.2rem;

  .Counter {
    padding: 0;

    &__quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props =>
        props.disabled
          ? "var(--palette-black-s0-l10)"
          : "var(--palette-black-s0-l10)"};
      color: ${props =>
        props.disabled
          ? "var(--palette-black-s2-l17)"
          : "var(--palette-white)"};
      border-radius: 0.4rem;
      padding: 0 0.6rem;
      font-weight: 600;
      margin: 0 0.2rem;
      font-size: 1.2rem;
    }

    &__button {
      padding: 0.8rem;
      height: 2.8rem;
      width: 3.6rem;
      border: 0;
      border-radius: 0.4rem;
      box-shadow: 0 0 0 0;

      path {
        fill: ${props =>
          props.disabled
            ? "var(--palette-black-s2-l17)"
            : "var(--palette-white)"};
      }

      &:disabled {
        border: 0;
        box-shadow: none;
        background-color: ${props =>
          props.disabled
            ? "var(--palette-black-s0-l10)"
            : "var(--palette-black-s0-l10)"};

        path {
          fill: var(--palette-gray-s0-l77);
        }
      }
    }
  }
`;

export default CounterStyled;
