import { Drawer } from "artisn-ui-react";
import styled from "styled-components";

import { SearchBarProductsDrawerStyledProps as Props } from "./SearchBarProductsDrawer.types";
import CONSTANTS from "config/constants";

const { desktop } = CONSTANTS.BREAKPOINTS;

const SearchBarProductsDrawerStyled = styled(Drawer)<Props>`
  &.SearchBarProductsDrawer {
    display: none;
    background-color: var(--palette-black-s2-l17);

    @media (max-width: ${desktop}px) {
      display: block;
      width: 100%;
      max-height: calc(
        var(--sizes-page-maxHeight) + env(safe-area-inset-bottom)
      );
      min-height: calc(
        var(--sizes-page-minHeight) + env(safe-area-inset-bottom)
      );
    }
  }

  .SearchBarProducts__search-bar {
    padding: 1.2rem 1.6rem;
    padding-right: 0;
    max-width: 100%;
  }
`;

export default SearchBarProductsDrawerStyled;
