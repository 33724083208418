import styled from "styled-components";

import { MapAddressPreviewStyledProps as Props } from "./MapAddressPreview.types";

const MapAddressPreviewStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  height: 4.8rem;
  padding: 0 2.4rem;
  padding-right: 1.6rem;
  margin-top: 1.2rem;
  cursor: pointer;

  .MapAddressPreview {
    &__info-card {
      .InfoCard {
        &__content {
          padding-left: 1.6rem;
          width: 100%;

          &__title {
            font-size: 1.6rem;
            font-weight: 400;
            color: var(--palette-white) !important;
            font-family: "Helvetica", sans-serif;

            &:hover {
              color: var(--palette-white);
            }
          }

          &__value {
            display: none;
          }
        }
      }
    }
  }
`;

export default MapAddressPreviewStyled;
