import styled from "styled-components";

import { HorizontalProductCardStyledProps as Props } from "./HorizontalProductCard.types";

const HorizontalProductCardStyled = styled.div<Props>`
  .HorizontalProductCard {
    &__product-details {
      cursor: default;
      display: grid;
      grid-template: 1.8rem auto auto / 10rem 1fr;
      grid-template-areas:
        "image name price"
        "image name total" "image description total" "image controls controls";
      grid-template-columns: 7.6rem;
      column-gap: 2rem;
    }

    &__image {
      grid-area: image;

      .Image__img {
        object-position: center;
        object-fit: cover;
      }
    }

    &__name {
      grid-area: name;
      font-family: inherit;
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--palette-white);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 3.6rem;
    }

    &__description {
      display: block;
      grid-area: description;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l61);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 0.8rem;
      width: 100%;
    }

    &__price {
      align-self: flex-start;
      grid-area: price;

      &-container {
        display: flex;
        justify-content: flex-end;
      }

      .ProductBasePriceInfo--normal {
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--palette-white);
        line-height: 1.6rem;
        font-family: inherit;
        margin-top: 0;
      }
    }

    &__controls {
      grid-area: controls;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.6rem;
    }

    &__counter {
      .Counter {
        &__quantity {
          width: 1.6rem;
          font-size: 1.2rem;
        }
      }
    }

    &__btn {
      &-delete {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin-left: 0.8rem;
      }

      &-edit {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        padding: 0;
        font-family: inherit;
        font-weight: 700;
        font-size: 1.6rem;
        color: var(--palette-white);

        svg {
          margin-left: 0.8rem;
        }
      }
    }

    &__total {
      align-self: flex-start;
      grid-area: total;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;

export default HorizontalProductCardStyled;
