import { useCountries } from "@sushicorp/contexts";
import { useGlobal } from "@sushicorp/contexts";
import { useFetchUser, useFetchUserSettings } from "@sushicorp/services";
import { usePutUser, usePutUserSettings } from "@sushicorp/services";
import { getLocaleISOStringUtility } from "@sushicorp/utils";
import { Modal } from "artisn-ui-react";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import Styles from "./DataProtectionModal.styles";
import { DataProtectionModalProps as Props } from "./DataProtectionModal.types";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const { CUSTOMER_SERVICE_EMAIL } = CONSTANTS.GENERAL;

const DataProtectionModal: React.FC<Props> = props => {
  const t = useI18n();
  const auth = useAuth();
  const { mutateAsync: updateUser } = usePutUser(auth);
  const { data: user } = useFetchUser(auth);
  const { selectedCountry } = useCountries();
  const { data: userSettings, isFetching } = useFetchUserSettings(auth);
  const { mutateAsync: updateUserSettings } = usePutUserSettings(auth);
  const [acceptDataTerms, setAcceptDataTerms] = useState(false);
  const { setOpenAcceptedPrivacyDataModal } = useGlobal();
  const { id: defaultCountryId } = selectedCountry;
  const { settings } = userSettings ?? {};
  const { emailNotifications = true } = settings ?? {};
  const [acceptEmails, setAcceptEmails] = useState<boolean>(true);

  /**
   * Handles the accept data terms checkboxes
   *
   * Sends privacy accept date to the backend
   * Sets email notifications to true if the user accepts them
   *
   * @since 1.0.0
   */
  const acceptDataTermsHandler = async () => {
    await updateUser({
      uid: user?.uid ?? "",
      email: user?.email ?? "",
      name: user?.name ?? "",
      lastname: user?.lastname ?? "",
      country: {
        id: defaultCountryId
      },
      privacyAcceptDate: acceptDataTerms ? getLocaleISOStringUtility() : null
    });

    await updateUserSettings({
      uid: user?.uid ?? "",
      settings: {
        emailNotifications: acceptEmails ?? false,
        pushNotifications: acceptEmails ?? false
      }
    });

    setOpenAcceptedPrivacyDataModal(false);
  };

  useEffect(() => {
    if (isFetching) return;

    setAcceptEmails(emailNotifications);
  }, [emailNotifications, isFetching]);

  return (
    <Modal {...props} className="DataProtectionModal__modal">
      <Styles className="DataProtectionModal">
        <p className="DataProtectionModal__title">
          {t.terms.dataProtection.title}
        </p>
        <p className="DataProtectionModal__paragraph">
          {t.terms.dataProtection.description.first}
        </p>
        <p className="DataProtectionModal__paragraph">
          {t.terms.dataProtection.description.second(CUSTOMER_SERVICE_EMAIL)}
        </p>
        <div className="DataProtectionModal__checkbox">
          <Checkbox
            label="&nbsp;"
            name="acceptTerms"
            defaultChecked={acceptDataTerms}
            onChange={() => setAcceptDataTerms(prev => !prev)}
          />
          <div className="DataProtectionModal__checkbox__label">
            <span>{t.common.preTerms} </span>
            <Link href="/profile/terms" passHref>
              <span className="DataProtectionModal__checkbox__link">
                {t.common.midTerms}
              </span>
            </Link>
            <span> {t.common.postTerms}</span>
          </div>
        </div>
        <div className="DataProtectionModal__checkbox">
          <Checkbox
            label="&nbsp;"
            name="acceptEmails"
            disabled={isFetching}
            defaultChecked={acceptEmails}
            onChange={() => setAcceptEmails(prev => !prev)}
          />
          <div className="DataProtectionModal__checkbox__label">
            <span>{t.profile.notifications.description} </span>
          </div>
        </div>
        <Button
          className="DataProtectionModal__button"
          disabled={!acceptDataTerms || isFetching}
          onClick={acceptDataTermsHandler}
        >
          {t.common.accept}
        </Button>
      </Styles>
    </Modal>
  );
};

DataProtectionModal.defaultProps = {};

export default DataProtectionModal;
