import { usePayments, useStores } from "@sushicorp/contexts";
import { usePostVerifyPaymentezOTP } from "@sushicorp/services";
import React, { useState } from "react";
import { flushSync } from "react-dom";
import OtpInput from "react18-input-otp";

import Styles from "./ModalPaymentezOtpVerification.styles";
import { ModalPaymentezOtpVerificationProps as Props } from "./ModalPaymentezOtpVerification.types";
import Button from "../Button/Button";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

import CloseSVG from "../../../../public/assets/images/close.svg";

const { STORAGE } = CONSTANTS;
const { PAYMENTEZ_TRANSACTION_REFERENCE } = STORAGE;

const ModalPaymentezOtpVerification: React.FC<Props> = props => {
  const [otpValue, setOtpValue] = useState("");
  const t = useI18n();
  const [isLoading, setIsLoading] = useState(false);
  const paymentezContext = usePayments();
  const auth = useAuth();
  const { mutateAsync: verifyOtp } = usePostVerifyPaymentezOTP(auth);
  const { reset: resetVerifyOtp } = usePostVerifyPaymentezOTP(auth);
  const { otpVerificationConfig, setOtpVerificationConfig } = paymentezContext;
  const { resetOtpVerificationConfig, setCloseCode } = paymentezContext;
  const [error, setError] = useState("");

  const { visible, onClose } = otpVerificationConfig;
  const { onPressOtpVerificationAdditionalAction } = otpVerificationConfig;
  const { isPayment } = otpVerificationConfig;
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};

  /**
   * Function to change the value of the otp input.
   *
   * @since 1.0.0
   * @param {string} value The value of the input
   */
  const onChange = (value: string) => setOtpValue(value);

  /**
   * Function to submit the value of the otp.
   *
   * @since 1.0.0
   */
  const onSubmitOtp = async () => {
    try {
      if (otpValue.length !== 6) return;
      if (!storeId) return;
      flushSync(() => {
        setIsLoading(true);
      });
      const transactionId = localStorage.getItem(
        PAYMENTEZ_TRANSACTION_REFERENCE
      );
      if (!transactionId) throw new Error(t.errors.paymentez.transactionId);
      const data = await verifyOtp({
        transactionId,
        OTPcode: otpValue,
        storeId: storeId.toString(),
        isPayment
      });
      const { status, card, statusDetailCode } = data ?? {};
      setCloseCode(statusDetailCode);
      if (status === "success") {
        localStorage.removeItem(PAYMENTEZ_TRANSACTION_REFERENCE);
        await onPressOtpVerificationAdditionalAction?.(card);
      }

      if (status === "pending") {
        throw new Error(t.errors.paymentez.otpFailure);
      }

      if (status === "failure") {
        throw new Error(t.errors.paymentez.otpFailure);
      }

      flushSync(() => {
        setIsLoading(false);
      });
      setError("");
      setOtpValue("");
      resetVerifyOtp();
      resetOtpVerificationConfig();
    } catch (e) {
      resetVerifyOtp();
      setError(e.message);
      if (e.message === t.errors.paymentez.otpFailure) {
        onCloseModal();
      }
    }
  };

  /**
   * Handler of the modal when is closed.
   *
   * @since 1.0.0
   */
  const onCloseModal = () => {
    setIsLoading(false);
    setError("");
    setOtpVerificationConfig({ visible: false, isPayment: false });
    setOtpValue("");
    resetOtpVerificationConfig();
    onClose?.();
  };

  const headerNode = (
    <div className="ModalPaymentezOtpVerification__header">
      <p className="ModalPaymentezOtpVerification__title">
        {t.paymentezModal.verifyCard}
      </p>
    </div>
  );

  return (
    <Styles
      className="ModalPaymentezOtpVerification"
      header={headerNode}
      onClose={onCloseModal}
      opened={visible}
      closeIcon={<CloseSVG />}
    >
      <OtpInput
        value={otpValue}
        onChange={onChange}
        numInputs={6}
        inputStyle="ModalPaymentezOtpVerification__otp--input"
        containerStyle="ModalPaymentezOtpVerification__otp--container"
        isInputNum
        onSubmit={onSubmitOtp}
        isDisabled={isLoading}
        hasErrored={!!error}
        errorStyle="ModalPaymentezOtpVerification__otp--error"
      />
      {error ? (
        <p className="ModalPaymentezOtpVerification__error">{error}</p>
      ) : null}
      <Button
        className="ModalPaymentezOtpVerification__button"
        onClick={onSubmitOtp}
        isLoading={isLoading}
      >
        {t.paymentezModal.verifyCode}
      </Button>
    </Styles>
  );
};

ModalPaymentezOtpVerification.defaultProps = {};

export default ModalPaymentezOtpVerification;
