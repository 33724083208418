import { Unmodal, useModal } from "react-unmodal";

import InfoActionModal from "../InfoActionModal/InfoActionModal";

import FullBagSVG from "../../../../public/assets/images/fish-in-bag.svg";

const Confirm = Unmodal.create(
  ({
    title = "Algunos productos de tu carrito podrían perderse con esta acción",
    message = "Al cambiar tu dirección podrías perder algunos de tus productos."
  }) => {
    const modal = useModal();

    const resolve = (value: boolean) => () => {
      modal.resolve(value);
      modal.close();
    };

    return (
      <InfoActionModal
        className="InfoShoppingCartModal"
        opened={modal.isOpen}
        cancelAction={resolve(false)}
        cancelText="Cancelar"
        confirmAction={resolve(true)}
        confirmText="Cambiar dirección"
        title={title}
        closeOnClickOutside={false}
        description={message}
        onClose={resolve(false)}
        icon={
          <div>
            <FullBagSVG />
          </div>
        }
      />
    );
  }
);

export const InfoShoppingCartModal = (props: any) =>
  Unmodal.open(Confirm, props);
