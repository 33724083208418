import { useGeo } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useCountries } from "@sushicorp/contexts";
import { useFetchStores } from "@sushicorp/services";
import { Store } from "artisn/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Styles from "./ChooseStoreInMap.styles";
import { ChooseStoreInMapProps as Props } from "./ChooseStoreInMap.types";
import Button from "components/global/Button/Button";
import { InfoShoppingCartModal } from "components/global/InfoShoppingCartModal/InfoShoppingCartModal";
import Map from "components/global/Map/Map";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import useI18n from "hooks/useI18n";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import { Google } from "types/geo.types";
import { notify } from "utils/common.utils";

const ChooseStoreInMap: React.FC<Props> = props => {
  const t = useI18n();
  useRequestNavigatorGeo();
  const { mapStore, setMapStore, setStep, onClose, setPreviousStep } = props;
  const [selectedPlaceInMap, setSelectedPlaceInMap] =
    useState<Google.Coordinates>();
  const { selectedCoordinates } = useGeo();
  const { data: stores } = useFetchStores(notify);
  const { setSelectedStore } = useStores();
  const { lat, lng } = useCountries().selectedCountry;
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const defaultCoords = useMemo(() => ({ lat, lng }), [lat, lng]);
  const { address } = mapStore ?? {};

  const inputChangeHandler = useCallback((place: Google.Geocode) => {
    const { geometry } = place;
    const { location } = geometry;
    setSelectedPlaceInMap(location);
  }, []);

  const addStoreHandler = () => {
    emptyCartHandler();
    setSelectedStore(mapStore);
    setPreviousStep(2);
    setStep(1);
    onClose?.();
  };

  const storeClickHandler = (store: Store) => {
    setMapStore(store);
  };

  const storeInfoClickHandler = (store: Store) => {
    storeClickHandler(store);
    setPreviousStep(2);
    setStep(3);
  };

  const clickHandler = async () => {
    if (!isEmptyShoppingCart) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (isConfirmed) {
          addStoreHandler();
          return;
        }
        throw new Error("The promise in the modal is rejected");
      } catch (e) {
        console.error(e.message);
      }
      return;
    }
    addStoreHandler();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "static";
  }, []);

  return (
    <Styles className="ChooseStoreInMap">
      <SearchAddress onChange={inputChangeHandler} />
      <div className="ChooseStoreInMap__map">
        <Map
          defaultZoom={17}
          center={selectedPlaceInMap ?? selectedCoordinates ?? defaultCoords}
          stores={stores}
          mapStore={mapStore}
          address={address}
          onStoreClick={storeClickHandler}
          onStoreInfoClick={storeInfoClickHandler}
          onStoreChange={setMapStore}
          showCoverage
        />
      </div>
      <div className="ChooseStoreInMap__confirm">
        <Button
          className="ChooseStoreInMap__confirm__button"
          onClick={clickHandler}
        >
          {t.profile.address.confirmLocation}
        </Button>
      </div>
    </Styles>
  );
};

ChooseStoreInMap.defaultProps = {};

export default ChooseStoreInMap;
