import styled from "styled-components";

import { InfoActionModalStyledProps as Props } from "./InfoActionModal.types";
import CONSTANTS from "config/constants";

const { tablet, desktop } = CONSTANTS.BREAKPOINTS;

const InfoActionModalStyled = styled.div<Props>`
  width: ${props => (props.isRating ? "52rem" : "100%")};
  max-width: ${props => (props.isRating ? "52rem" : "45rem")};
  min-width: 32rem;
  padding: 3.2rem;
  padding-top: ${props => (props.isRating ? "3.6rem" : "6.4rem")};
  padding-bottom: ${props =>
    props.isRating && !props.hideButtons ? "9.2rem" : "3.2rem"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--palette-black-s2-l17);

  @media (max-width: ${desktop + 1}px) {
    min-height: 100vh;
    width: 100vw;
    padding-top: 2.4rem;
  }

  &&& .InfoActionModal {
    &__icon {
      width: 64px;
      height: 64px;
    }

    &__go-back {
      background-color: transparent;
      position: absolute;
      border: none;
      top: 1.6rem;
      left: 1.6rem;
      cursor: pointer;
    }

    &__title {
      padding-top: ${props => (props.hasIcon ? "1.6" : "6.4")}rem;
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: ${props => (props.isRating ? "1.6rem" : "0")};
      color: ${props =>
        props.isRating ? "var(--palette-white)" : "var(--palette-white)"};
      font-family: inherit;
      font-weight: 700;
    }

    &__description {
      padding-top: 1.6rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      color: ${props =>
        props.isRating ? "var(--palette-white)" : "var(--palette-white)"};
    }

    &__buttons {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      padding-top: 2.4rem;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
      }
    }

    &__button {
      flex: 1;
      padding: 1.6rem 2rem;
      font-size: 1.6rem;
      font-weight: 600;
      border-radius: 12rem;

      &:first-child {
        margin-top: 1.6rem;
      }

      &:first-child:hover {
        background-color: unset;
        color: var(--palette-primary);
      }

      .Button__wrapper {
        white-space: nowrap;
      }
    }
  }
`;

export default InfoActionModalStyled;
