import { Button as ButtonUI } from "artisn-ui-react";
import styled, { css } from "styled-components";

import { ButtonColor, ButtonStyledProps as Props } from "./Button.types";

const mapType = (props: Props) => {
  const { type, color } = props;
  switch (type) {
    case "FILLED":
      return mapColorByFilledType(color);
    case "BORDER":
      return mapColorByBorderType(color);
    case "LINK":
      return mapColorByLinkType(color);
    default:
      return mapColorByFilledType(color);
  }
};

const mapColorByFilledType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        background-color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-primary);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "primary-light":
      return css`
        border: 0.1rem solid var(--palette-primary-light);
        background-color: var(--palette-primary-light);
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l98);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-primary-light);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        border: 0.1rem solid var(--palette-black-s2-l17);
        background-color: var(--palette-black-s2-l17);
        color: var(--palette-white);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l20);
            border: 0.1rem solid var(--palette-gray-s0-l20);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l70);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "white":
      return css`
        border: 0.2rem solid var(--palette-white);
        background-color: var(--palette-white);
        color: var(--palette-blue-s68-l26);

        &:hover {
          &:not([disabled]) {
            border: 0.2rem solid var(--palette-white);
            background-color: var(--palette-white);
            color: var(--palette-blue-s68-l26);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-white);
          background-color: var(--palette-white);
          color: var(--palette-blue-s68-l26);

          .Button__loading__icon {
            path {
              fill: var(--palette-blue-s68-l26);
            }
          }
        }
      `;

    case "gray-light":
      return css`
        border: 0.1rem solid var(--palette-gray-s0-l97);
        background-color: var(--palette-gray-s0-l97);
        color: var(--palette-black-s0-l10);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l97);
            border: 0.1rem solid var(--palette-gray-s0-l97);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l98);
          color: var(--palette-primary);
          box-shadow: none;

          .Button__loading__icon {
            path {
              fill: var(--palette-primary);
            }
          }
        }
      `;
    default:
      return css`
        background-color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-primary);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;
  }
};

const mapColorByBorderType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        border: 0.2rem solid var(--palette-primary);
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.2rem solid var(--palette-primary);
            color: var(--palette-white);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-gray-s0-l70);
          background-color: var(--palette-gray-s0-l70);
          color: var(--palette-white);
          box-shadow: none;

          .Button__loading__icon {
            path {
              fill: var(--palette-white);
            }
          }
        }
      `;

    case "primary-light":
      return css`
        border: 0.2rem solid var(--palette-primary-light);
        color: var(--palette-primary-dark);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-primary);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-primary-light);
          color: var(--palette-gray-s0-l80);
          box-shadow: none;

          .Button__loading__icon {
            path {
              fill: var(--palette-gray-s0-l80);
            }
          }
        }
      `;

    case "white":
      return css`
        color: var(--palette-white);
        border: 0.2rem solid var(--palette-white);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-white);
            border: 0.2rem solid var(--palette-white);
          }
        }

        &:disabled {
          color: var(--palette-gray-s0-l73);
          background-color: var(--palette-white);
          box-shadow: none;

          .Button__loading__icon {
            path {
              fill: var(--palette-gray-s0-l73);
            }
          }
        }
      `;

    case "black":
      return css`
        border: 0.2rem solid var(--palette-black);
        color: var(--palette-black);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l20);
            color: var(--palette-white);
            border: 0.2rem solid var(--palette-black);
          }
        }

        &:disabled {
          color: var(--palette-gray-s0-l70);
          box-shadow: none;

          .Button__loading__icon {
            path {
              fill: var(--palette-gray-s0-l70);
            }
          }
        }
      `;

    case "red":
      return css`
        color: var(--palette-red-s95-l66);
        border: 0.2rem solid var(--palette-red-s95-l66);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-white);
            background-color: var(--palette-red-s95-l66);
          }
        }

        &:disabled {
          background-color: var(--palette-white);
          color: var(--palette-red-s95-l66);
          border: 0.2rem solid var(--palette-red-s95-l66);
          box-shadow: none;
          opacity: 0.5;

          .Button__loading__icon {
            path {
              fill: var(--palette-red-s95-l66);
            }
          }
        }
      `;

    default:
      return css`
        border: 0.2rem solid var(--palette-primary);
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.2rem solid var(--palette-primary);
            color: var(--palette-white);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-gray-s0-l95);
          background-color: var(--palette-gray-s0-l95);
          color: var(--palette-gray-s0-l80);
          box-shadow: none;
        }
      `;
  }
};

const mapColorByLinkType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-primary);
          }
        }

        &:disabled {
          color: var(--palette-primary);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        color: var(--palette-black);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-gray-s0-l20);
          }
        }

        &:disabled {
          box-shadow: none;
        }
      `;

    default:
      return css`
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-primary);
          }
        }

        &:disabled {
          color: var(--palette-primary);
          box-shadow: none;
        }
      `;
  }
};

const ButtonStyled = styled(ButtonUI)<Props>`
  &.Button {
    display: flex;
    box-shadow: none !important;
    outline: none !important;
    background-color: none;
    border-radius: 7.6rem;
    cursor: pointer;
    padding: 0.8rem 2.4rem;
    font-size: 1.6rem;
    ${mapType}

    &:hover {
      &:not([disabled]) {
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }

  .Button {
  }
`;

export default ButtonStyled;
