import styled from "styled-components";

import { CommonPlaceholderStyledProps } from "./CommonPlaceholder.types";

const CommonPlaceholderStyled = styled.div<CommonPlaceholderStyledProps>`
  border-radius: 0.4rem;
  width: 100%;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 3.2rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 0.8rem;

  .CommonPlaceholder {
    &__shine {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      background-color: var(--palette-gray-s0-l30);
      width: 4.8rem;
      height: 32rem;
      opacity: 0.2;
      animation: shine 1.5s ease-in-out infinite;
    }

    &__left {
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.4rem;
      background-color: var(--palette-gray-s0-l30);
    }

    &__right {
      margin-left: 1.2rem;

      &__top {
        width: 17.6rem;
        height: 1.6rem;
        margin-top: 0.6rem;
        background-color: var(--palette-gray-s0-l30);
        border-radius: 0.4rem;
      }

      &__bottom {
        width: 11.2rem;
        height: 1.6rem;
        margin-top: 0.6rem;
        background-color: var(--palette-gray-s0-l30);
        border-radius: 0.4rem;
      }
    }
  }
`;

export default CommonPlaceholderStyled;
