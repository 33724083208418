import styled from "styled-components";

import { InfoNotificationStyledProps as Props } from "./InfoNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const InfoNotificationStyled = styled(Notification)<Props>`
  display: ${props => (props.opened ? "block" : "none")};
  padding-top: 1.2rem;
  border-radius: 1.2rem;

  @media (max-width: ${mobile}px) {
    padding: 1rem;
    display: ${props => (props.opened ? "grid" : "none")};
    grid-template-columns: auto 10.8rem;
    align-items: center;
  }

  .InfoNotification {
    &__title {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;
      padding-top: 1.2rem;
      margin-bottom: 0.4rem;
      color: var(--palette-white) !important;

      @media (max-width: ${mobile}px) {
        padding-top: 0;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }

    &__button {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &__description {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      color: var(--palette-white) !important;
    }

    &__icon {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 90%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: ${mobile}px) {
        display: none;
      }
    }

    &__description {
      padding-bottom: 1.6rem;
      color: var(--palette-black-s0-l10);

      @media (max-width: ${mobile}px) {
        padding-bottom: 0.8rem;
        line-height: 1.6rem;
        font-size: 1.4rem;
      }
    }

    &__button {
      color: var(--palette-white);
      font-weight: 600;
      padding: 0.8rem 2rem;
      max-width: 20.8rem;
      border-radius: 0.8rem;

      &,
      &:hover:not([disabled]) {
        border: 0.2rem solid var(--palette-white);
        background-color: transparent;
      }

      .Button__wrapper {
        justify-content: center;
      }

      &--desktop {
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &--mobile {
        grid-column: 2/3;
        grid-row: 1/3;
        max-width: 8rem;
        height: 4.8rem;
        padding: 1rem;
        display: none;

        @media (max-width: ${mobile}px) {
          display: block;
        }
      }
    }
  }
`;

export default InfoNotificationStyled;
