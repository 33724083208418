import { Providers } from "artisn/analytics";
import { initializeApp } from "firebase/app";
import { getAuth as firebaseGetAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyBJI5zqVG-S7sIN6WTgJPa0Hr2xHAgYnoA",
      authDomain: "sc-noe-dev.firebaseapp.com",
      databaseURL: "https://sc-noe-dev-default-rtdb.firebaseio.com",
      projectId: "sc-noe-dev",
      storageBucket: "sc-noe-dev.appspot.com",
      messagingSenderId: "831102701726",
      appId: "1:831102701726:web:d6c134753df527b4b46b8f",
      measurementId: "G-Y05ECJ77S3",
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookPixelId: "2120203041523513"
    },
    production: {
      apiKey: "AIzaSyDo5KrTtN4hsOhAA1K_z_ZKKaxr4AedKkU",
      authDomain: "sc-noe-prod.firebaseapp.com",
      databaseURL: "https://sc-noe-prod-default-rtdb.firebaseio.com",
      projectId: "sc-noe-prod",
      storageBucket: "sc-noe-prod.appspot.com",
      messagingSenderId: "881007812338",
      appId: "1:881007812338:web:d34a27c9430e9e2acd3a6b",
      measurementId: "G-YBGHR6HMEX",
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookPixelId: "907297177013883"
    }
  }[env];
})();

const firebaseConfig = {
  projectId: getENVs?.projectId ?? "",
  apiKey: getENVs?.apiKey ?? "",
  authDomain: `${getENVs?.projectId}.firebaseapp.com`,
  databaseURL: getENVs?.databaseURL,
  appId: getENVs?.appId
};

export const app = initializeApp(firebaseConfig);
export const getAuth = firebaseGetAuth(app);
export const db = getFirestore(app);

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookPixelId ?? "",
  version: "2.0"
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
