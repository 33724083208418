import styled from "styled-components";

import { StorePreviewStyledProps as Props } from "./StorePreview.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const StorePreviewStyled = styled.div<Props>`
  padding: 0 2.4rem;
  margin: 1.6rem 0;
  cursor: pointer;

  @media (max-width: ${mobile - 1}px) {
    padding: 1.2rem 0.4rem;
  }

  .StorePreview {
    &__info {
      display: flex;
      align-items: center;
      padding-top: 0.4rem;

      &__fill {
        min-width: 7.2rem;
        background-color: var(--palette-gray-s0-l98);
        border-radius: 2rem;
        padding: 0.4rem 0.8rem;
        display: flex;
        align-items: center;
      }

      &__fill-point {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: var(--palette-green-s100-l40);
      }

      &__fill-text {
        padding-left: 0.6rem;
      }

      &__fill-closed {
        background-color: var(--palette-red-s61-l87);

        .StorePreview__info__fill-point {
          background-color: var(--palette-red-s60-l60);
        }

        .StorePreview__info__fill-text {
          color: var(--palette-red-s60-l60);
        }
      }

      &__distance {
        display: flex;
        padding-left: 0.4rem;
        color: var(--palette-gray-s0-l80);
      }

      &__distance-status {
        padding-right: 0.6rem;
        color: var(--palette-gray-s0-l80);
      }

      &__distance-measurement {
        color: var(--palette-gray-s0-l80);
        text-transform: none;
      }
    }

    &__info,
    &__address {
      color: var(--palette-gray-s0-l80);
      text-transform: capitalize;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .InfoCard {
    &__content {
      display: flex;
      flex-direction: column-reverse;

      @media (max-width: ${mobile - 1}px) {
        padding: 0 0.4rem;
        flex: 1;
      }

      &__title {
        & .StorePreview__address {
          font-family: "Montserrat", sans-serif;
        }
      }

      &__value {
        color: var(--palette-white);
        font-weight: 400;
        padding: 0;
        text-transform: capitalize;
      }
    }

    &__icon {
      svg {
        path {
          fill: var(--palette-white);
        }
      }
    }

    &__icon-to-right {
      @media (max-width: ${mobile - 1}px) {
        margin-left: 0;
      }
    }
  }

  &.selected {
    border-left: 0.1rem solid var(--palette-primary);

    .InfoCard__content__value {
      color: var(--palette-primary);
    }

    .StorePreview__info__distance,
    .StorePreview__info__distance-status {
      color: var(--palette-green-s63-l42);
    }

    .InfoCard__icon-to-right {
      path {
        fill: var(--palette-primary);
      }
    }
  }
`;

export default StorePreviewStyled;
