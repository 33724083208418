import styled from "styled-components";

import { CounterModifierStyledProps as Props } from "./CounterModifier.types";

const CounterModifierStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0;

  .CounterModifier {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    &__counter {
      background-color: var(--palette-primary) !important;

      .Counter {
        &__button {
          background-color: var(--palette-primary) !important;
          padding: 0;
          width: 2.4rem;
        }

        &__button:disabled {
          background-color: var(--palette-blue-s70-l70) !important;
          & > svg {
            stroke: var(--palette-gray-l90-a10) !important;
          }
        }

        &__quantity {
          background-color: var(--palette-primary) !important;
          color: var(--palette-white) !important;
          font-size: 1rem;
        }
      }
    }

    &__image {
      margin: 0 0.8rem;
    }

    &__title {
      font-size: 1.4rem;
      color: var(--palette-white) !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: 600;

      &--active {
        font-weight: 600;
      }
    }
  }
`;

export default CounterModifierStyled;
