import { getMaxHeight } from "@sushicorp/utils";
import { ModalProps } from "artisn-ui-react";
import styled from "styled-components";

import { CouponsModalStyledProps as Props } from "./CouponsModal.types";
import { CouponModalHeaderStyledProps } from "./CouponsModal.types";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";

const { mobile, desktop } = CONSTANTS.BREAKPOINTS;

export const ModalStyled = styled(Modal)<ModalProps>`
  width: 50rem;
  height: auto;
  background-color: var(--palette-black-s2-l17) !important;

  .Modal {
    &__body {
      background-color: var(--palette-black-s2-l17);
    }
  }

  @media (max-width: ${desktop + 1}px) {
    min-height: 100vh;
    width: 100vw;
    padding-top: 2.4rem;
  }

  .CouponsModal {
  }
`;

const CouponsModalStyled = styled.div<Props>`
  height: min(${getMaxHeight()}, 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;

  @media (max-width: ${desktop + 1}px) {
    min-height: 90vh;
  }

  .CouponsModal {
    &__header {
      padding-top: 1.2rem;
    }

    &__input {
      padding-left: 2.4rem;
      padding-right: 2.4rem;

      @media (max-width: ${mobile}px) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }

    &__coupon-input {
      .RedeemCouponInput {
        &__text-input .TextInput__input {
          height: 5.6rem;
          background-color: var(--palette-black-s0-l10);
          border: none;

          &:hover {
            border: none;
          }
        }

        &__input-button {
          top: 3.2rem;

          &,
          &:hover {
            background-color: var(--palette-black-s2-l17);
            border: var(--palette-black-s2-l17);
            color: var(--palette-white);
          }
        }
      }
    }

    &__BenefitsList {
      padding: 2.4rem;
    }

    &__error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__apply-error {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }

    &__redeem-error {
      padding-top: 0.8rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }

    &__divider {
      width: 100%;
      height: 0.4rem;
      margin: 2rem 0rem;
      background-color: var(--palette-black-s0-l4);
    }

    &__apply {
      display: flex;
      flex-direction: column;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 1.6rem;
      margin-top: 2.4rem;

      &__button {
        width: 100%;
        padding: 1.6rem;
        border-radius: 1.8rem;
        font-weight: 700;

        &:disabled {
          background-color: var(--palette-primary);
          opacity: 0.6;
          border: var(--palette-primary);
        }
      }
    }
  }
`;

export const ModalHeaderStyled = styled.div<CouponModalHeaderStyledProps>`
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  font-weight: 600;
  font-size: 2rem;
  color: var(--palette-white);

  .CouponsModal__header {
    color: var(--palette-white);
    font-weight: 700;
  }
`;

export default CouponsModalStyled;
