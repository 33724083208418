import styled from "styled-components";

import { EmptyCartStyledProps as Props } from "./EmptyCart.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const EmptyCartStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 35.6rem;

  .EmptyCart {
    &__icon {
      margin-bottom: 1.6rem;

      @media (max-width: ${mobile}px) {
        display: none;
      }
    }

    &__title {
      padding-top: 2rem;
      padding-bottom: 1.2rem;
      font-weight: 700;
      font-size: 2.4rem;
      text-align: center;
      line-height: 3.6rem;
      color: var(--palette-white);
      padding: 1.2rem 1.6rem;
    }

    &__description {
      padding-bottom: 4rem;
      font-size: 1.2rem;
      font-family: inherit;
      font-weight: 400;
      color: var(--palette-white);
      text-align: center;
    }

    &__button {
      margin-top: 1.2rem;
      padding: 1.2rem 2.4rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;

export default EmptyCartStyled;
